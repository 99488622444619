@import "../../assets/styles/utils";

.root-footer{
    background-color: #fff;
    position: relative;
    z-index: 1;
    @include res(padding-top, 250px, 150 / 250);
}
.footer{
    color: #fff;
    background: $color-green;
    // @include res(margin-top, 250px, 150 / 250);
    .backtop{
        position: absolute;
        z-index: 10;
        cursor: pointer;
        bottom: 320px;
        right: 100px;
        // @include res(bottom,320px, 200 / 320);
        // @include res(right, 100px, 10 / 100);
        .backtop-icon{
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            @include res(width, 60px, 40 / 60);
            @include res(height, 60px, 40 / 60);
            .iconfont {
              color: #fff;
              @include res(font-size, 24px, 20 / 24);
            }
        }
        .backtop-text{
            text-transform: uppercase;
            font-size: 14px;
            text-align: center;
            font-weight: lighter;
            @include res(margin-top,10px, 5 / 10);
        }
    }
    .cont{
        position: relative;
        align-items: flex-start;
        justify-content: flex-start;
        @include res(display, flex, (xs:block));
        @include res(padding-top, 100px, 20 / 100);
        @include res(padding-bottom, 85px, 15 / 85);
        @include res(top, -160px, -80 / -160);
        @include res(margin-bottom, -160px, -80 / -160);
        &::after{
            content: '';
            height: 100%;
            background: url(./images/footer-logo.png) no-repeat bottom right $color-blue;
            position: absolute;
            top: 0;
            z-index: 1;
            @include res(right,0,(sm:-5%));
            @include res(background-size, null, (xs:80%));
            @include res(width, 200%, (xs:110%));
        }
        .left{
            position: relative;
            z-index: 2;
            flex-shrink: 0;
            @include res(font-size, $text-size-md-base, $text-size-md-ratio);
            @include res(width,37.85%,(xs:100%));
            .contact{
                @include res(text-align, left, (xs:center));
                h1{
                    font-weight: bold;
                    // overflow: hidden;
                    @include res(font-size, $title-size-xs-base, $title-size-xs-ratio);
                }
                h2 {
                    // overflow: hidden;
                  @include res(font-size, 50px, (lg:40px, mmd:34px, md:22px));
                }
                div{
                    p{
                        overflow: hidden;
                    }
                    p:last-child{
                        color: $color-green;
                        margin-top: 10px;
                    }
                }
            }
            .ewm-cont{
                display: flex;
                align-items: center;
                @include res(justify-content, flex-start, (xs:center));
                @include res(margin-top,20px, 10 / 20);
                .ewm-item{
                    flex-shrink: 0;
                    position: relative;
                    &:first-child{
                        margin-right: 20px;
                    }
                    .icon{
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        // border: 1px solid $color-green;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        .iconfont{
                            color: $color-green;
                            transition: $anime-duration $anime-bezier;
                            @include res(font-size,34px, 26 / 34);
                        }
                        svg{
                            rect{
                                stroke:$color-green;
                            }
                            rect+rect{
                                stroke:#fff;
                            }
                        }
                    }
                    .img{
                        position: absolute;
                        bottom: 120%;
                        left: 50%;
                        transform: translateX(-50%);
                        opacity: 0;
                        visibility: hidden;
                        transition: $anime-duration $anime-bezier;
                        &::after{
                            content: '';
                            border: 6px solid;
                            border-color: #fff transparent transparent transparent;
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            margin-left: -3px;
                        }
                        img{
                            display: block;
                            height: auto;
                            @include res(width, 180px, (mmd:150px, sm:100px,xs:150px));
                        }
                    }
                    &:hover{
                        .icon{
                            .iconfont{
                                color: #fff;
                            }
                        }
                        .img{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
        .right {
            position: relative;
            z-index: 2;
            flex-shrink: 0;
            @include res(width, 62.15%, (xs:100%));
            @include res(display, null, (xs:none));
            .title{
                @include res(margin-bottom,50px, 25 / 50);
                @include res(font-size, $title-size-xs-base, $title-size-xs-ratio);
                &::after{
                    content: '';
                    display: block;
                    width: 44px;
                    height: 2px;
                    background: $color-green;
                    @include res(margin-top, 20px, 10 / 20);
                }
            }
            .footer-link{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                .link-item{
                    flex-shrink: 0;
                    width: 50%;
                    .link-tit{
                        font-weight: bold;
                        @include res(font-size, $text-size-md-base, $text-size-md-ratio);
                        @include res(margin-bottom,10px, 5 / 10);
                    }
                    a{
                        -webkit-transition-duration: $anime-duration;
                        transition-duration: $anime-duration;
                        -webkit-transition-timing-function: cubic-bezier(.25, .74, .22, .99);
                        transition-timing-function: cubic-bezier(.25, .74, .22, .99);
                        color: #bdbfc7;
                        &:hover{
                            color: $color-green;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
    .copyright{
        align-items: center;
        justify-content: space-between;
        @include res(text-align, null, (sm:center));
        @include res(display, flex, (sm:block));
        @include res(padding-top,25px, 10 / 25);
        @include res(padding-bottom, 25px, 10 / 25);
        @include res(line-height, 2, (xs:1.2));
        .right{
            display: flex;
            align-items: center;
            @include res(justify-content,flex-end,(sm:center));
            .map{
                cursor: pointer;
                @include res(margin-left,30px, 10 / 30);
                @include res(display,null,(sm:none));
            }
        }
    }
}

@media screen and (max-width: 1680px) {
  .footer .backtop{
      bottom: 280px;
      right: 40px;
  }
}
@media screen and (max-width: 1600px) {
  .footer .backtop{
      bottom: 230px;
      right: 70px;
  }
}
@media screen and (max-width: 1440px) {
  .footer .backtop{
      bottom: 230px;
      right: 60px;
  }
}
@media screen and (max-width: 1366px) {
  .footer .backtop{
      bottom: 230px;
      right: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .footer .backtop{
      bottom: 240px;
      right: 50px;
  }
}
@media screen and (max-width: 768px) {
  .footer .backtop{
      bottom: 250px;
      right: 30px;
  }
}
@media screen and (max-width: 425px) {
  .footer .backtop{
      bottom: 215px;
      right: 10px;
  }
}
@media screen and (max-width: 375px) {
  .footer .backtop{
      bottom: 230px;
      right: 10px;
  }
}