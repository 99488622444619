@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.ttf') format('truetype'),
    url('../fonts/Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

html{
  // font-size: 62.5%;
  overflow-x: hidden;
  // overflow: hidden;
}
html *{
  outline: none;
}

body {
  font-family:'roboto','Times New Roman','微软雅黑';
  overflow-x: hidden;
  line-height: 2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  // overflow: hidden;
  @include res(font-size, $text-size-base, $text-size-ratio);
}
// .comp-root{
//   height: 100vh;
// }

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}

//电脑手机适配
.pc-show {
  display: block !important;
}

.pc-show-flex {
  display: flex !important;
}

.mob-show {
  display: none !important;
}

.mob-show-flex {
  display: none !important;
}

//半透明黑色遮罩
.root-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .6);
  display: none;

  &.mask-top {
    z-index: 10;
  }

  &.mask-bottom {
    z-index: 3;
  }
}

//主体内容部分宽度
.container {
  box-sizing: content-box;
  width: 100%;
  max-width: 1400px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

  &.container-1200 {
    max-width: 1200px;
  }
  &.w1000{
    max-width: 1000px;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    box-sizing: border-box;
    max-width: 1330px;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}

@media screen and (max-width: 768px) {
  .pc-show,
  .pc-show-flex {
    display: none !important;
  }

  .mob-show {
    display: block !important;
  }

  .mob-show-flex {
    display: flex !important;
  }
}

.pdbtm-250{
  @include res(padding-bottom, 250px, 150 / 250);
}

//分页
.page,#page ul{
  display: flex;
  align-items: center;
  justify-content: center;
  a,li{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: #d2d2d2;
    margin: 0 5px;
    border-radius: 50%;
    transition: $anime-duration $anime-bezier;
    @include res(width,60px, 40 / 60);
    @include res(height,60px, 40 / 60);
    &:hover{
      color: $color-green;
      border-color: $color-green;
    }
    &.active,&.hover,&.xl-active{
      color: #fff;
      border-color: $color-green;
      background: $color-green;
    }
    &.xl-prevPage,&.xl-nextPage{
      display: none;
    }
  }
}

//浮动banner
.fixed-banner{
  .fixed-banner-cont{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  .fixed-banner-zhanwei{
    position: relative;
    z-index: -1;
  }
}


//公共更多按钮
.common-more-link{
  overflow: hidden;
  div{
    @include res(height, 62px, 45 / 62);
  }
  a{
    display: block;
    border-radius: 50px;
    background: $color-green;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    @include res(width,205px, 150 / 205);
    @include res(height, 62px, 45 / 62);
    @include res(line-height, 62px, 45 / 62);
    span{
      position: relative;
      z-index: 1;
    }
    &::after{
      content: '';
      width: 100%;
      height: 0;
      background: $color-blue;
      display: block;
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: 0;
      transition: $anime-duration $anime-bezier;
    }
    &:hover{
      &::after{
        height: 100%;
      }
    }
  }
}

//内页banner公用样式
.ny-banner{
  .fixed-banner-cont{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @include res(height,700px, 400 / 700);
  }
  .container{
    @include res(padding-top,220px, 100 / 220);
    //面包屑
    .location{
      font-size: 14px;
      color: rgba(255,255,255,.5);
      a{
        transition: $anime-duration $anime-bezier;
        &:hover{
          color: rgba(255,255,255,1);
        }
      }
      span{
        margin-left: 5px;
      }
    }
    //title
    .banner-title{
      color: #fff;
      font-weight: bold;
      overflow: hidden;
      @include res(margin-top,10px, 5 / 10);
      @include res(margin-bottom, 5px, 0 / 5);
      @include res(font-size,56px,(lg:44px, mmd:38px, md:26px))
    }
    //描述
    .banner-desc{
      color: #fff;
      overflow: hidden;
      @include res(font-size,$title-size-xs-base,$title-size-xs-ratio);
    }
    //banner中的导航样式（一级）
    .banner-nav{
      overflow-x: auto;
      overflow-y: hidden;
      @include res(margin-top,50px, 20 / 50);
      >div{
        display: flex;
        align-items: center;
        a{
          display: flex;
          align-items: center;
          transition: $anime-duration $anime-bezier;
          white-space: nowrap;
          color: #fff;
          @include res(margin-right,45px, 15 / 45);
          @include res(font-size,$text-size-lg-base,$text-size-lg-ratio);
          &::before{
            content: '';
            display: block;
            background-color: $color-green;
            border-radius: 50%;
            transform: scale(0);
            transition: $anime-duration $anime-bezier;
            @include res(width,6px, 4 / 6);
            @include res(height,6px, 4 / 6);
            @include res(margin-right, 15px, 7 / 15);
          }
          &:last-child{
            margin-right: 0;
          }
          &.active,&.hover{
            color: $color-green;
            &::before{
              transform: scale(1);
            }
          }
          &.not-active{
            color: #fff;
            &::before{
              transform: scale(0);
            }
          }
        }
      }
    }
    //产品banner导航样式（二级）
    .pro-menu{
      position: relative;
      @include res(margin-top,60px, 20 / 60);
      ul{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        li{
          @include res(height,140px, 80 / 140);
          @include res(padding-right,35px, 10 / 35);
          @include res(padding-left,35px, 10 / 35);
          &:first-child{
            padding-left: 0;
          }
          h1{
            color: #fff;
            cursor: pointer;
            position: relative;
            transition: $anime-duration $anime-bezier;
            @include res(font-size,$title-size-sm-base,$title-size-sm-ratio);
            &::after{
              content: '';
              display: block;
              background-color: $color-green;
              border-radius: 50%;
              transition: $anime-duration $anime-bezier;
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translateX(-50%) scale(0);
              transform-origin: center;
              @include res(width,7px, 5 / 7);
              @include res(height,7px, 5 / 7);
            }
          }
          .submenu{
            position: absolute;
            left: 0;
            color: #fff;
            display: none;
            overflow-x: auto;
            overflow-y: hidden;
            width: 100%;
            bottom: 0;
            @include res(padding-top,60px, 0 / 60);
            @include res(font-size,$text-size-lg-base,$text-size-lg-ratio);
            div{
              display: flex;
              align-items: center;
            }
            a{
              display: flex;
              align-items: center;
              transition: $anime-duration $anime-bezier;
              white-space: nowrap;
              @include res(margin-right,45px, 15 / 45);
              &::before{
                content: '';
                display: block;
                background-color: $color-green;
                border-radius: 50%;
                transform-origin: center;
                transform: scale(0);
                transition: $anime-duration $anime-bezier;
                @include res(width,6px, 4 / 6);
                @include res(height,6px, 4 / 6);
                @include res(margin-right, 15px, 7 / 15);
              }
              &:last-child{
                margin-right: 0;
              }
              &.active,&.hover{
                color: $color-green;
                &::before{
                  transform: scale(1);
                }
              }
              &.not-active{
                color: #fff;
                &::before{
                  transform: scale(0);
                }
              }
            }
          }
          &.active,&.hover{
            h1{
              color: $color-green;
              &::after{
                transform: translateX(-50%) scale(1);
              }
            }
          }
          &.not-active{
            h1{
              color: #fff;
              &::after{
                transform: translateX(-50%) scale(0);
              }
            }
          }
        }
      }
    }
  }
}

//内页title公用样式
.ny-title{
  color: $color-title;
  font-weight: bold;
  text-shadow: 0px 0px 3px #fff;
  @include res(text-align,null,(xs:center));
  @include res(font-size,$title-size-md-base,$title-size-md-ratio);
}

//装饰性圆圈，带动画
.animate-circle{
  svg{
    // width: 600px;
    // height: 600px;
    // @include res(width,600px,(md:500px,xs:400px));
    // @include res(height, 600px, (md:500px, xs:400px));
    rect:first-child {
      // width: calc(100% - 80px);
      // height: calc(100% - 80px);
      // stroke-width: 80;
      // stroke: $color-blue-light3;
      fill: none;
      rx: 600;
      ry: 600;
      // x: 40;
      // y: 40;
      // stroke-dasharray: 90%;
      // stroke-dashoffset: 90%;
      transition: 1.8s $anime-bezier;
      // @include res(stroke-width, 80, (md:60,xs:40));
      // @include res(x, 40, (md:30, xs:20));
      // @include res(y, 40, (md:30, xs:20));
      // @include res(width, calc(100% - 80px), (md:calc(100% - 60px), xs:calc(100% - 40px)));
      // @include res(height, calc(100% - 80px), (md:calc(100% - 60px), xs:calc(100% - 40px)));
    }
    rect:nth-child(2){
      // width: calc(100% - 2px);
      // height: calc(100% - 2px);
      stroke-width: 1;
      // stroke: $color-blue-light3;
      fill: none;
      rx: 600;
      ry: 600;
      x: 1;
      y: 1;
      // stroke-dasharray: 90%;
      // stroke-dashoffset: 90%;
      transition: 1.8s $anime-bezier;
    }
  }
  // &.active {
  //   svg {
  //     rect {
  //       stroke-dashoffset: 0%;
  //     }
  //   }
  // }
}

//鼠标经过，圆圈从无到有动画
.has-svg-circle{
  position: relative;
  svg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    rect{
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      stroke-width: 1;
      stroke: rgba(255,255,255,.6);
      fill: none;
      rx: 50;
      ry: 50;
      x: 1;
      y: 1;
    }
    rect+rect{
      stroke: rgba(255, 255, 255, 1);
      stroke-dasharray: 400%;
      stroke-dashoffset: 400%;
      transition: $anime-duration $anime-bezier;
    }
  }
  &:hover{
    svg{
      rect+rect{
        stroke-dashoffset: 0%;
      }
    }
  }
}


@keyframes rain {
  0% {
    // opacity: 0;
    transform: translateY(-5%);
  }

  25% {
    // opacity: 0.7;
    transform: translateY(0%);
  }

  50% {
    // opacity: 1;
    transform: translateY(5%);
  }

  75% {
    // opacity: 0.7;
    transform: translateY(0%);
  }

  100% {
    // opacity: 0;
    transform: translateY(-5%);
  }
}