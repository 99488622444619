@font-face {
  font-family: "iconfont"; /* Project id 2446513 */
  src: url('iconfont.woff2?t=1643358599649') format('woff2'),
       url('iconfont.woff?t=1643358599649') format('woff'),
       url('iconfont.ttf?t=1643358599649') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconbofang:before {
  content: "\eb5e";
}

.iconarrow-down-bold:before {
  content: "\e686";
}

.iconguanbi2:before {
  content: "\e622";
}

.iconarrowup:before {
  content: "\e749";
}

.iconsanjiaoright:before {
  content: "\e621";
}

.iconsanjiaoleft:before {
  content: "\e8dc";
}

.iconsanjiaotop-copy:before {
  content: "\e8dd";
}

.iconjiehungonglve:before {
  content: "\e60d";
}

.iconertong:before {
  content: "\e6b1";
}

.iconrenshenpregnancy:before {
  content: "\e63a";
}

.iconfuwuerji:before {
  content: "\e7e1";
}

.iconhunjia:before {
  content: "\e61d";
}

.iconlingshi:before {
  content: "\e625";
}

.iconxianchangpeihufuwu:before {
  content: "\e8d5";
}

.iconinsurance:before {
  content: "\e69e";
}

.icontijianguanli:before {
  content: "\e63b";
}

.iconzhinengyun:before {
  content: "\e607";
}

.iconshengyu:before {
  content: "\e67e";
}

.iconmaidian:before {
  content: "\e60a";
}

.iconjierifuli:before {
  content: "\e61b";
}

.iconmaidian1:before {
  content: "\e604";
}

.iconxinpian:before {
  content: "\e61e";
}

.iconzhuangtai_chanjia:before {
  content: "\e67a";
}

.iconzhuangtai_bingjia:before {
  content: "\e67c";
}

.iconshengxuehudongbeifen:before {
  content: "\e647";
}

.icontishi:before {
  content: "\e611";
}

.iconshebao:before {
  content: "\e72f";
}

.iconrenzheng:before {
  content: "\e626";
}

.iconnianjia:before {
  content: "\e608";
}

.icongongshangbaoxian:before {
  content: "\e87e";
}

.iconweixin:before {
  content: "\e618";
}

.icondouyin:before {
  content: "\e8db";
}

.iconSearch:before {
  content: "\e61c";
}

.iconshengri:before {
  content: "\e61f";
}

