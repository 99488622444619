@import "../../assets/styles/utils";

.root-header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1015;
    &.search-header{
        .header{
            background: #fff;
            color: $color-title;
            border-bottom: 1px solid rgba(0, 0, 0, .1);
        }
    }
    &.scroll-header{
        .header{
            @include res(height, 90px, 60 / 90);
            background: #fff;
            color: $color-title;
            border-bottom: 1px solid rgba(0, 0, 0, .1);
            .header-nav {
              ul {
                li.active>a::after,li.hover>a::after {
                  background: $color-green;
                }
              }
            }
            .header-search {
              .hamburger>div,
              .hamburger>div:after,
              .hamburger>div:before,
              .hamburger>view,
              .hamburger>view:after,
              .hamburger>view:before {
                background-color: $color-title;
              }
            }
        }
    }
    .header{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        transition: $anime-duration $anime-bezier;
        border-bottom: 1px solid rgba(255, 255, 255, .2);
        @include res(height, $header-height-base, $header-height-ratio);
        &:hover{
            background: #fff;
            color: $color-title;
            border-bottom: 1px solid rgba(0, 0, 0, .1);
            .header-nav{
                ul{
                    li.active>a::after,li.hover>a::after{
                        background: $color-green;
                    }
                }
            }
            .header-search {
                .hamburger>div,
                .hamburger>div:after,
                .hamburger>div:before,
                .hamburger>view,
                .hamburger>view:after,
                .hamburger>view:before{
                    background-color: $color-title;
                }
            }
        }
        .header-logo{
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            display: flex;
            align-items: center;
            @include res(left, 50px, 10 / 50);
            a{
                display: block;
                height: 40%;
                img{
                    display: block;
                    height: 100%;
                    width: auto;
                }
            }
        }
        .header-search{
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            display: flex;
            align-items: center;
            @include res(right, 50px, 10 / 50);
            .search-icon{
                cursor: pointer;
                .iconfont{
                    transition: $anime-duration $anime-bezier;
                    @include res(font-size,24px, 22 / 24);
                    &.iconguanbi2{
                        display: none;
                    }
                }
                &.active{
                    .iconfont {
                      &.iconguanbi2 {
                        display: inline-block;
                      }
                      &.iconSearch {
                        display: none;
                      }
                    }
                }
            }
            .language{
                @include res(margin-left,30px, 20 / 30);
                @include res(font-size, $text-size-md-base, $text-size-md-ratio);
            }
            .hamburger{
                margin-left: 20px;
                @include res(display,none,(sm:block));
            }
        }
        .search-cont{
            position: absolute;
            bottom: 100%;
            left: 0;
            right: 0;
            width: 100%;
            z-index: -1;
            background-color: #fff;
            transition: $anime-duration $anime-bezier;
            opacity: 0;
            &.active{
                opacity: 1;
                bottom: 0;
                transform: translateY(100%);
            }
            .search-form{
                width: 100%;
                max-width: 820px;
                margin: 0 auto;
                padding-left: 10px;
                padding-right: 10px;
                position: relative;
                @include res(margin-top,20px, 10 / 20);
                @include res(margin-bottom, 20px, 10 / 20);
                input{
                    border: none;
                    width: 100%;
                    border-bottom: 1px solid #252d67;
                    @include res(height,50px, 40 / 50);
                    &::-webkit-input-placeholder {
                        color: $color-green;
                    }
                    &:-moz-placeholder {
                        color: $color-green;
                    }
                    &:-ms-input-placeholder {
                        color: $color-green;
                    }
                }
                button{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 15px;
                    border: none;
                    background: none;
                    cursor: pointer;
                    padding-left: 15px;
                    padding-right: 15px;
                    .iconfont{
                        font-size: 24px;
                    }
                }
            }
        }
        .header-nav{
            height: 100%;
            @include res(display,block,(sm:none));
            ul{
                display: flex;
                align-items: center;
                height: 100%;
                li{
                    height: 100%;
                    &:not(:first-child){
                        @include res(padding-left,15px, 0 / 15);
                        @include res(padding-right,15px, 0 / 15);
                    }
                    &:hover{
                        >a{
                            color: $color-green;
                        }
                        .subnav{
                            opacity: 1;
                            transform: scaleY(1);
                            // transform: translateY(100%);
                            background: #fff;
                        }
                    }
                    
                    &.active,&.hover{
                        >a{
                            &::after{
                                transform: translateX(-50%) scale(1);
                                background: #fff;
                            }
                        }
                    }
                    &.not-active{
                        >a{
                            &::after{
                                transform: translateX(-50%) scale(0);
                            }
                        }
                    }
                    >a{
                        display: flex;
                        align-items: center;
                        height: 100%;
                        position: relative;
                        transition: $anime-duration $anime-bezier;
                        @include res(padding-left,20px, 10 / 20);
                        @include res(padding-right, 20px, 10 / 20);
                        @include res(font-size, $text-size-md-base, $text-size-md-ratio);
                        &::after{
                            content: '';
                            display: block;
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            position: absolute;
                            top: 70%;
                            left: 50%;
                            transform-origin: center;
                            transform: translateX(-50%) scale(0);
                            transition: $anime-duration $anime-bezier;
                        }
                    }
                    .subnav{
                      position: absolute;
                      top: 100%;
                      left: 0;
                      right: 0;
                      z-index: -1;
                      transition: $anime-duration $anime-bezier;
                      transform: scaleY(0);
                      transform-origin: top;
                      opacity: 0;
                      .subnav-cont{
                          width: 100%;
                          max-width: 720px;
                          margin: 0 auto;
                      }
                    }
                    .subnav:not(.pro-subnav) {
                        text-align: right;
                        .subnav-cont{
                            a{
                                display: inline-block;
                                font-weight: bold;
                                transition: $anime-duration $anime-bezier;
                                color: $color-text;
                                @include res(font-size, $text-size-md-base, $text-size-md-ratio);
                                @include res(margin-left,60px, 20 / 60);
                                @include res(padding-top, 35px, 15 / 35);
                                @include res(padding-bottom, 35px, 15 / 35);
                                &:hover{
                                    color: $color-green;
                                }
                            }
                        }
                    }
                    .subnav.pro-subnav{
                        color: $color-text;
                        overflow: hidden;
                        @include res(padding-top,20px, 10 / 20);
                        @include res(padding-bottom, 60px, 20 / 60);
                        &::after{
                            content: '';
                            display: block;
                            width: 350px;
                            height: 350px;
                            border: 50px solid $color-blue-light3;
                            position: absolute;
                            top: 0;
                            right: 0;
                            border-radius: 50%;
                            transform: translate3d(40%, -45%, 0);
                        }
                        .subnav-cont{
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-end;
                            .subnav-item{
                                flex-shrink: 0;
                                @include res(margin-right,80px, 40 / 80);
                                .item-title{
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 5px;
                                    .icon{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border: 1px solid #eaeaea;
                                        width: 50px;
                                        height: 50px;
                                        border-radius: 50%;
                                    }
                                    .tit{
                                        color: $color-green;
                                        font-weight: bold;
                                        margin-left: 15px;
                                        @include res(font-size, $text-size-md-base, $text-size-md-ratio);
                                    }
                                }
                                .item-link{
                                    padding-left: 65px;
                                    a{
                                        display: block;
                                        transition: $anime-duration $anime-bezier;
                                        &:hover{
                                            color: $color-green;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .nav-mob{
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            bottom: 100%;
            background: $color-blue;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 30px 20px;
            color: #fff;
            transform: translateY(0);
            transition: $anime-duration $anime-bezier;
            &.active{
                transform: translateY(100%);
            }
            .nav-mob-close{
                text-align: right;
                margin-bottom: 20px;
                >div{
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border-radius: 50%;
                    background: #fff;
                    cursor: pointer;
                }
                .iconfont{
                    color: #000;
                    font-size: 20px;
                    font-weight: bold;
                }
            }
            .menu{
                ul{
                    li{
                        transition: $anime-duration $anime-bezier;
                        &.active{
                            border-bottom: 1px solid rgba(255, 255, 255, .2);
                            >a{
                                color: $color-green;
                                border-bottom: 1px solid $color-green;
                                >div{
                                    border: 1px solid $color-green;
                                    background: $color-green;
                                    .iconfont{
                                        color: #fff;
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                        }
                        >a{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-bottom: 1px solid rgba(255, 255, 255, .4);
                            padding-top: 8px;
                            padding-bottom: 8px;
                            font-size: 16px;
                            transition: $anime-duration $anime-bezier;
                            >div{
                                width: 24px;
                                height: 24px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 50%;
                                border: 1px solid rgba(255, 255, 255, .4);
                                transition: $anime-duration $anime-bezier;
                                .iconfont{
                                    font-size: 12px;
                                    transition: $anime-duration $anime-bezier;
                                }
                            }
                        }
                        .sub-slide{
                            display: none;
                            transition: all $anime-bezier;
                        }
                        .subnav-tabs{
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-start;
                            .tabs-item{
                                flex-shrink: 0;
                                width: 50%;
                                box-sizing: border-box;
                                h1{
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                    padding-left: 10px;
                                    color: rgba(255, 255, 255, .7);
                                    border-bottom: 1px solid rgba(255, 255, 255, .1);
                                }
                            }
                        }
                        .subnav{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            .subnav-cont{
                                flex-shrink: 0;
                                width: 100%;
                                padding-top: 10px;
                                padding-bottom: 5px;
                                a{
                                    display: block;
                                    padding-left: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}